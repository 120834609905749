let user = JSON.parse(localStorage.getItem('user'));
export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Tracking Surat',
        to: '/tracking',
        icon: 'cil-folder-open',
      },      
      {
        _name: 'CSidebarNavItem',
        name: 'Template Naskah',
        to: '/template',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Jukrah',
        to: '/jukrah',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Perpustakaan',
        to: '/perpustakaan',
        icon: 'cil-folder-open',
      },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Laporan',
      //   route: '/laporan',
      //   icon: 'cil-folder-open',
      //   items: [
      //     {
      //       name: 'Lap. Penjualan',
      //       to: '/laporan/penjualan'
      //     },
      //     {
      //       name: 'Lap. Hutang',
      //       to: '/laporan/hutang'
      //     },
      //     {
      //       name: 'Lap. Stok Barang',
      //       to: '/laporan/stok'
      //     },
      //     {
      //       name: 'Lap. Pembelian',
      //       to: '/laporan/pembelian'
      //     },
      //     {
      //       name: 'Lap. Laba Rugi',
      //       to: '/laporan/labarugi'
      //     },
          
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Stake Coins',
      //   to: '/coins',
      //   icon: 'cil-folder-open',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Members',
      //   to: '/members',
      //   icon: 'cil-folder-open',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Users',
      //   to: '/users',
      //   icon: 'cil-folder-open',
      // },
      {
        _name: 'CSidebarNavTitle',
        name: 'Setting',
        _children: ['Setting']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Profilku',
        to: '/Profilku',
        icon: 'cil-folder-open'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Ganti Password',
        to: '/gantipassword',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'cil-account-logout'
      },
    ]
  }
]