<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1"><b>Copyright &copy; {{new Date().getFullYear()}} SEMARI - Dinas Perumahan Rakyat dan Kawasan Permukiman Provinsi Kalimantan Barat.</b> All rights reserved.</span>
    </div> 
    <!-- <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
