export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'DASHBOARD',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },      
      {
        _name: 'CSidebarNavDropdown',
        name: 'DATA MASTER',
        route: '/master',
        icon: 'cil-list',
        items: [
          {
            name: 'Kategori',
            to: '/kategori',
            icon: 'cil-check'
          },
          {
            name: 'Layer',
            to: '/layer',
            icon: 'cil-check'
          },          
          {
            name: 'Tabular',
            to: '/tabular',
            icon: 'cil-check'
          },
          {
            name: 'Dokumen',
            to: '/dokumen',
            icon: 'cil-check'
          },
          {
            name: 'Galeri',
            to: '/galeri',
            icon: 'cil-check'
          },
          {
            name: 'Berita',
            to: '/berita',
            icon: 'cil-check'
          },
          {
            _name: 'CSidebarNavItem',
            name: 'User',
            to: '/user',
            icon: 'cil-user',
          },
          // {
          //   name: 'Subkategori',
          //   to: '/subkategori',
          //   icon: 'cil-check'
          // },
          // {
          //   name: 'Sub-Subkategori',
          //   to: '/sub_subkategori',
          //   icon: 'cil-check'
          // },
          // {
          //   name: 'Data Tabular',
          //   to: '/tabular',
          //   icon: 'cil-check'
          // }          
        ]
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'AKTIFITAS',
      //   to: '/aktifitas',
      //   icon: 'cil-laptop',
      // },
      
      {
        _name: 'CSidebarNavDropdown',
        name: 'KONFIGURASI',
        route: '/setting',
        icon: 'cil-settings',
        items: [
          {
            name: 'Profilku',
            to: '/profilku',
            icon: 'cil-check'
          },
          {
            name: 'Ganti Password',
            to: '/gantipassword',
            icon: 'cil-check'
          }         
        ]
      },    
      
      {
        _name: 'CSidebarNavItem',
        name: 'LOGOUT',
        to: '/logout',
        icon: 'cil-arrow-right'
      },
    ]
  }
]